@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800;900&display=swap');

:root {
    --color-light-bg-accent: #FF5D7B;
    --color-light-border-accent: #FF5D7B;
    --color-light-text-accent: #FF5D7B;
    --color-light-graphic-accent: #FF5D7B;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
    font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

#root {
    height: 100%;
}

.App {
    height: 100%;
}
